import { EventType, pushEvent } from "./index";

/**
 * Registration Error
 */

export const event28 = (erroMsg: string) => {
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.inputerror,
      eventLabel: erroMsg,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: "Error"
  });
};
