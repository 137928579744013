import { EventType, pushEvent } from "./index";

/**
 * Registration Complete
 */

export const event27 = (customerId: string) => {
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.registration,
      eventLabel: customerId,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    }
  });
};
