import { EventType, pushEvent } from "./index";

export const event50 = () => {
  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.Acquisition,
      eventLabel: "SignUp For Exciting offers, product updates and more from SheaMoisture.",
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.conversion
    },
    subcategory: "Lead"
  };
  pushEvent(EventData);
};
