import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import "./styles.scss";
import {
  PortableText,
  SanityCtaLabelsInterface,
  SanityErrorMessagesLabelsInterface,
  SanityFormsLabelsInterface,
  SanityPasswordStrengthLabelsInterface,
  Slug
} from "../../types/SanityTypes";
// @ts-ignore
import Field from "../Forms/Field";
import { Link } from "../Link";
import { useForm } from "react-hook-form";
import { userSignup } from "../../services/salesforce/Auth";
import LoaderIcon from "../../images/icons/loader";
import Button from "../Button";
import { navigate } from "gatsby";
import Section from "../Section";
import RichText from "../RichText";
import { pushEpsilonEmail } from "web-us/src/web-common/components/EpsilonTag";
import { event27 } from "../../analytics/event27";
import { event28 } from "../../analytics/event28";
import { event50 } from "../../analytics/event50";
import { sendErrorEvents } from "../../utils/sendErrorEvents";

export type SignUpFormInterface = {
  newsLetterSubscriptionText?: PortableText;
  sanityLogin?: Slug;
  topics?: string[];
  prefetchData?: any;
  isfullWidth?: boolean;
} & SanityCtaLabelsInterface &
  SanityFormsLabelsInterface &
  SanityErrorMessagesLabelsInterface &
  SanityPasswordStrengthLabelsInterface;

export const SignUpForm: FunctionComponent<SignUpFormInterface> = ({
  newsLetterSubscriptionText = "",
  sanityLogin,
  formsLabels,
  errorMessages,
  prefetchData = {},
  isfullWidth
}) => {
  const login = sanityLogin;

  const PHONE_REGEX = new RegExp(/^\+?\d{0,1}\s?-?\(?(\d{3})\)?\s?-?(\d{3})\s?-?(\d{4})$/);
  const handlePhoneNumberValidate = (phoneNumber: any) => {
    return PHONE_REGEX.test(phoneNumber);
  };

  const formFields: any = [
    [
      {
        type: "text",
        name: "firstName",
        label: formsLabels?.firstName,
        placeholder: "Enter your first name",
        rules: {
          required: true,
          pattern: {
            value: /^[A-Za-z]+$/i,
            message: "First name should not contain any numbers, special symbols"
          },
          maxLength: {
            value: 50,
            message: "First name should not exceed more than 50 characters"
          }
        },
        message: errorMessages?.validFirstName
      }
    ],
    [
      {
        type: "text",
        name: "lastName",
        label: formsLabels?.lastName,
        placeholder: "Enter your last name",
        rules: {
          required: true,
          pattern: {
            value: /^[A-Za-z]+$/i,
            message: "Last name should not contain any numbers, special symbols"
          },
          maxLength: {
            value: 50,
            message: "Last name should not exceed more than 50 characters"
          }
        },
        message: errorMessages?.validLastName
      }
    ],
    [
      {
        type: "text",
        name: "phone",
        label: formsLabels?.phone,
        placeholder: "Example:+1-123-456-7890",
        rules: {
          required: true,
          validate: (value: any) => {
            if (!handlePhoneNumberValidate(value)) {
              return "Invalid phone number. Please try again.";
            }
          },
          minLength: {
            value: 10,
            message: "Phone number should be at least 10 digits"
          }
        },
        message: errorMessages?.validPhone || "Please enter your phone number!"
      }
    ],
    [
      {
        type: "text",
        name: "email",
        label: formsLabels?.email,
        placeholder: "Enter your email",
        rules: {
          required: true,
          pattern: {
            value:
              /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: errorMessages?.validEmail || "Please enter your email!"
          }
        },
        message: errorMessages?.validEmail || "Please enter your email!"
      }
    ],
    [
      {
        type: "text",
        name: "confirmEmail",
        label: formsLabels?.confirmEmail || "Confirm Email",
        placeholder: "repeat email",
        rules: {
          required: true,
          validate: (val: string) => {
            if (watch("email") != val) {
              return "Your email does not match";
            }
          },
          pattern: {
            value:
              /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: errorMessages?.validEmail || "Please enter your email!"
          }
        },
        message: errorMessages?.validEmail || "Please enter your email!"
      }
    ],
    [
      {
        type: "password",
        name: "password",
        label: formsLabels?.password,
        placeholder: "must be 8 characters",
        rules: {
          required: true,
          pattern: {
            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$_%^&*]{8,}$/,
            message: errorMessages?.passwordRequirements
          }
        },
        message: errorMessages?.passwordRequirements
      }
    ],
    [
      {
        type: "password",
        name: "confirmpassword",
        label: formsLabels?.confirmpassword || "Confirm Password",
        placeholder: "repeat password",
        rules: {
          required: true,
          validate: (val: string) => {
            if (watch("password") != val) {
              return "Your password does not match";
            }
          },
          pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$"
        },
        message: errorMessages?.passwordRequirements
      }
    ],

    [
      {
        type: "switch",
        name: "subscribe",
        rules: {
          required: false
        }
      },
      { isText: true, text: newsLetterSubscriptionText }
    ]
  ];

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm();

  useEffect(() => {
    Object.keys(prefetchData).forEach(fieldName => {
      setValue(fieldName, prefetchData[fieldName].value);
    });
  }, [prefetchData]);

  const [isLoading, setIsLoading] = useState(false);
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isRegistrationCompleted, setIsRegistrationCompleted] = useState(false);
  const handleSubmitClick = () => {
    sendErrorEvents(errors, formFields);
  };
  const onSubmit = async (data: any) => {
    const { firstName, lastName, email, phone, password, subscribe } = data;
    setIsLoading(true);
    setIsFormSubmitted(true);
    setErrorMessage("");
    pushEpsilonEmail(email);
    if (subscribe) {
      event50();
    }
    await userSignup(firstName, lastName, email, phone, password)
      .then(res => {
        const { data } = res;
        setIsResponseReceived(true);
        setIsLoading(false);
        if (data.error) {
          event28(data.message);
          setErrorMessage(data.message);
        } else {
          setIsRegistrationCompleted(true);
          event27(data.customerId);
          setTimeout(() => {
            navigate("/login");
            setIsResponseReceived(true);
            setIsRegistrationCompleted(false);
          }, 5000);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        event28(error.message);
        setIsResponseReceived(false);
        setIsLoading(false);
      });
    window.registrationStarted = false;
  };

  return (
    <Section title="Do you want to register?">
      <div className="grid-row">
        <div className={`grid-col grid-col-lg-${isfullWidth ? "12" : "6"} ${!isfullWidth && "grid-col-lg-offset-3"}`}>
          <form onSubmit={handleSubmit(onSubmit)} className="c-signUpForm">
            {formFields.map((field: any, index: number) =>
              Array.isArray(field) ? (
                <div className="row sign-up-form-row" key={index}>
                  {field.map(field => {
                    return field.isText ? (
                      <div className="field-description">
                        <RichText data={field.text} />
                      </div>
                    ) : (
                      <Field
                        className="input-text"
                        key={field.name}
                        {...field}
                        disabled={prefetchData[field.name]?.disabled}
                        registerInput={register}
                        errors={errors}
                        message={field.message}
                        data-analytics-event26
                      />
                    );
                  })}
                </div>
              ) : (
                <Field
                  className="input-text"
                  key={field.name}
                  {...field}
                  registerInput={register}
                  errors={errors}
                  message={field.message}
                  data-analytics-event26
                />
              )
            )}
            <div className="row">
              <div className="col-12">
                <div className="col-md mt-3">
                  <Button
                    className="w-100 btn-login"
                    type="submit"
                    variant="nonary"
                    form="br-50"
                    disabled={isLoading}
                    onClick={handleSubmitClick}
                  >
                    Sign Up
                    {isLoading && <LoaderIcon width={13} height={13} className="c-signUpForm__loaderIcon" />}
                  </Button>
                </div>
                {isRegistrationCompleted && (
                  <div className="success-message text__size-h3">
                    Thank you! Your account has successfully been created. You will be redirected to the Login page.
                  </div>
                )}
              </div>
              {isFormSubmitted && isResponseReceived && errorMessage ? (
                <div className="container text-center smt-24">
                  <p className="text-secondary">{errorMessage}</p>
                </div>
              ) : null}
              {login ? (
                <div className="col-12">
                  <div className="col-md mt-5 login-block text-center">
                    <span>
                      Already have an account?&nbsp;
                      <Link _id={login?._id} to={login?.slug.current as string}>
                        Log in
                      </Link>
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </Section>
  );
};

export default SignUpForm;
