import * as React from "react";
import SignUpForm from "../../components/SignUpForm";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import { Col, Row } from "react-bootstrap";
import { graphql, PageProps } from "gatsby";
import {
  LocalizedContextInterface,
  InternalLink,
  Seo,
  ImageInterface,
  SanityColorList,
  PortableText
} from "../../types/SanityTypes";
import "./styles.scss";
import { LocalisedPageContext } from "../../types/PageTypes";
import HeroBanner from "../../components/HeroBanner";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

type SignUpProps = {
  signUp: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      heroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
      isVideoBanner: boolean;
    };
    _rawNewsLetterSubscriptionText?: PortableText;
    slug: {
      current: string;
    };
    seo: Seo;
  };
  sanityLogin: InternalLink;
} & LocalizedContextInterface;

type SignUpPageContext = LocalisedPageContext;

export const query = graphql`
  query SignUpPage($_id: String, $language: String) {
    signUp: sanitySignUp(_id: { eq: $_id }) {
      _id
      banner {
        heading
        subHeading
        primaryColor {
          value
          title
        }
        contentAlignment
        isVideoBanner
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        heroImage {
          alt
          ...ImageWithPreview
        }
        _rawHeroVideo(resolveReferences: { maxDepth: 10 })
      }
      _rawNewsLetterSubscriptionText
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    sanitySiteSettings(i18n_lang: { eq: $language }) {
      name
      description
    }
    sanityLogin(i18n_lang: { eq: $language }) {
      _id
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

const SignUp = (props: PageProps<SignUpProps, SignUpPageContext>) => {
  const { siteUrl } = useSiteMetadata();
  const site = props.data.sanitySiteSettings;
  const signUpPage = props.data.signUp;

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={signUpPage.seo.metaTitle}
      pageType={"signUp"}
      analyticsTagMeta={{ promoId: "941" }}
    >
      <SEO title={signUpPage.seo.metaTitle} description={signUpPage.seo.metaDescription} />
      <div className="page_sign-up">
        <Row>
          <Col>
            <HeroBanner
              image={signUpPage.banner?._rawHeroImage}
              sanityImage={signUpPage.banner?.heroImage}
              title={signUpPage.banner?.heading}
              alignment={signUpPage.banner?.contentAlignment}
              isVideoBanner={signUpPage.banner?.isVideoBanner}
              video={signUpPage.banner?._rawHeroVideo}
            />
            <SignUpForm
              newsLetterSubscriptionText={signUpPage._rawNewsLetterSubscriptionText}
              sanityLogin={props.data.sanityLogin}
              ctaLabels={props.data.sanityLabels?.ctaLabels}
              formsLabels={props.data.sanityLabels?.formsLabels}
              errorMessages={props.data.sanityLabels?.errorMessages}
              passwordStrength={props.data.sanityLabels?.passwordStrength}
            />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default SignUp;
